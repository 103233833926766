.heading-bar {
  padding-top: 15px;
}

.heading-bar > hr {
  margin-top: 5px;
}

.heading-bar .btn {
  margin-left: 10px;
}

.clickable-cell {
  font-weight: 600;
}

.clickable-cell, .clickable-cell-light {
  color: inherit;
  text-decoration: inherit;
}

.clickable-cell:hover, .clickable-cell-light:hover {
  text-decoration: underline;
}

td {
  vertical-align: middle;
}

td:last-of-type > svg:last-of-type {
  margin-right: 5px;
}

.details-grid {
  border-radius: 10px;
  border: 1px solid #c8c9ca;
  padding: 20px;
  background-color: #f8f9fa;
}

.details-grid div.col-md-4 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.details-grid-vertical div.row {
  margin-bottom: 0.25rem;
}

.details-grid-vertical div.row > div:first-child {
  font-weight: 600;
}

.details-title {
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.large-item {
  border-radius: 10px;
  border: 1px solid #c8c9ca;
  padding: 20px;
  width: 200px;
  min-height: 300px;
  text-align: center;
  cursor: pointer;
  transition: background-color ease-in-out .15s, border-color ease-in-out .15s;
}

.large-item:hover {
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.large-item a {
  text-decoration: none;
  color: inherit;
}

.large-item-icon {
  font-size: 3em;
  padding-bottom: 10px;
}

.react-calendar {
  border: inherit !important;
  font-family: inherit !important;
}

.react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth):not(.react-calendar__tile--active):not(:disabled) {
  color: inherit !important;
}

.react-calendar__tile--active {
  color: white !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active):not(:hover) {
  background: inherit !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active):hover {
  background-color: #e6e6e6 !important;
}

.react-calendar__tile--active {
  background-color: #0d6efd !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar button:disabled {
  background: inherit !important;
  color: rgba(16, 16, 16, 0.3) !important;
}
