#sidebar #top-logo {
    width: 100%;
}

#sidebar .nav-item {
    width: 100%;
}

#sidebar .nav-link {
    color: white !important;
}

#sidebar .name-toggle {
    color: white !important;
    cursor: pointer;
    white-space: normal !important;
}

#sidebar .name {
    font-weight: bold;
    display: inline-block;
}

#sidebar .nav .subitem {
    margin-left: 25px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#sidebar .nav .subitem:not(.subitem-mid) {
    display: none;
}

#sidebar .nav .subsubitem {
    margin-left: 50px;
}

#sidebar .nav .active {
    display: block !important;
}

#sidebar {
    padding-left: 7.5%;
    padding-right: 7.5%;
}
